import "./App.css";
import Layout from "@component/Layout";
import ProtectedRoutes from "@component/ProtectedRoutes";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import OfflineDetector from "./Components/OfflineDetector";
import AlertTost from "./Examples/AlertToast";
import LoginModal from "./Pages/UserModal";
import OtpModal from "./Pages/UserModal/OtpModal";
import CustomConfirmModal from "./Components/CustomConfirmModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserById, loggedUser } from "./Redux/features/userSlice";
import { handleAlert } from "./Redux/alertSlice";
import DummyModal from "./Pages/UserModal/DummyModal";
import Notification from "./Components/Notification";
import { handleIncrement } from "./Redux/features/assessmentSlice";
import { purchaseMembership } from "./Redux/features/memberSlice";
import DrawerComponents from "./Examples/options/Drawer";
import Seo from "./Components/Seo";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("_id");
  const token = localStorage.getItem("token");
  const { company } = useSelector((state) => state?.isCompany);
  // console.log(company)
  useEffect(() => {
    window.history.replaceState(null, "");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location?.pathname]);

  //Getting User logged
  useEffect(() => {
    if (userId && token) {
      dispatch(
        loggedUser(`${process.env.REACT_APP_API}/userLogin/${userId}`)
      ).then((response) => {
        // console.log(response?.payload)
        if (response?.payload?.success === false) {
          dispatch(
            handleAlert({
              isOpen: true,
              type: "error",
              msg: "Session Expired",
            })
          );
          localStorage.removeItem("_id");
          localStorage.removeItem("token");
          navigate("/");
        }
      });
    }
  }, [userId, token]);

  useEffect(() => {
    if (userId && token) {
      dispatch(
        purchaseMembership(
          `${process.env.REACT_APP_API}/getByUserIdPurchaseMembership/${userId}`
        )
      );
    }
  }, [userId]);

  // getting profile or user data  for every render
  useEffect(() => {
    if (userId && token) {
      dispatch(
        getUserById(`${process.env.REACT_APP_API}/getUserById/${userId}`)
      );
    }
  }, [token]);

  useEffect(() => {
    if (window.location.pathname !== "/AssessmentQuestion") {
      dispatch(handleIncrement(0));
    }
  }, [window.location.pathname]);

  return (
    <>
      <AlertTost />
      <LoginModal />
      <OtpModal />
      <DummyModal />
      <CustomConfirmModal />
      <DrawerComponents />
      {/* <OfflineDetector> */}
      <Layout>
        <Notification />
        {!location?.pathname.includes("productDetail")  && (
          <Seo
            title={
              location?.pathname.slice(1)
                ? location?.pathname.slice(1)
                : "Wellmaats"
            }
            icon={company?.fav_icon}
            image={company?.header_logo}
            keyword={location?.pathname.slice(1)}
            description={company?.description}
          />
        )}
        <ProtectedRoutes />
      </Layout>
      {/* </OfflineDetector> */}
    </>
  );
}

export default App;
