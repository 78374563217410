import React, { useEffect, useState } from "react";
import NavbarFirst from "@component/Navbar/NavbarFirst";
import NavbarSecond from "@component/Navbar/NavbarSecond";
import Footer from "@component/Footer";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import MobileFooter from "@component/MobileFooter";
import OfferNavbar from "../Navbar/OfferNavbar";
import StickyArrow from "../StickyArrow";
import MobileNavbar from "../Navbar/MobileNavbar";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const { getHome } = useSelector((state) => state?.isHome);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 450) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {getHome?.offerOnOccasion && <OfferNavbar />}
      <MobileNavbar />
      <Box
        sx={{
          display: {
            xs: "none",
            md: "block",
            position: "sticky",
            top: 0,
            zIndex: 10,
          },
        }}
      >
        <NavbarFirst />
        <NavbarSecond />
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          position: "fixed",
          bottom: "3rem",
          right: "2rem",
          zIndex: 20,
        }}
      >
        {scroll ? <StickyArrow /> : null}
      </Box>
      <main style={{ minHeight: "100vh" }}>{children}</main>
      <MobileFooter />
      {getHome && <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
